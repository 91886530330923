import * as React from "react";
import MaterialTable from "@material-table/core";
import { Box, Paper, Fab } from "@mui/material";
import api from "../../services/api";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { materialTableLocalization } from "../../config";
import { Rule } from "@mui/icons-material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FornecedorCadastro from "./fornecedorcadastro";
import FornecedorPedidoOrcamento from "./FornecedorPedidoOrcamento";
import FornecedorPedidoTomadaPreco from "./FornecedorPedidoTomadaPreco";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Fornecedor = () => {
    let user = useStoreState((state) => state.auth.user);
    let startLoading = useStoreActions((actions) => actions.nav.startLoading);
    let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

    const [columns, setColumns] = React.useState([]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <h4>Empresa - <i>INATIVO</i></h4>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Fornecedor">
                    <Tab label="Cadastro" {...a11yProps(0)} />
                    <Tab label="Tomada de preço" {...a11yProps(1)} />
                    <Tab label="Orçamento" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <FornecedorCadastro />
            </CustomTabPanel><CustomTabPanel value={value} index={1}>
                <FornecedorPedidoTomadaPreco />
            </CustomTabPanel><CustomTabPanel value={value} index={2}>
                <FornecedorPedidoOrcamento />
            </CustomTabPanel>
        </>
    );

}

export default Fornecedor;