import { RadioGroup, FormControlLabel, Radio as MUIRadio } from '@mui/material';
import { Controller } from "react-hook-form";

const Radio = ({ field, schema, useFormRef, options = [], ...props }) => {
    const { control } = useFormRef;
    return (
        <Controller
            name={field}
            control={control}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => (
                <RadioGroup
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...props}
                >
                    {options.map(option => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<MUIRadio inputRef={ref} />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            )}
        />
    );
};

export default Radio;
