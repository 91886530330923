export const linkEditais = {
    "AC": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/ac",
    },
    "AL": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/al",
    },
    "AP": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/ap",
    },
    "AM": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/am",
    },
    "BA": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/ba",
    },
    "CE": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/ce",
    },
    "DF": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/df",
    },
    "ES": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/es",
    },
    "GO": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/go",
    },
    "MA": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/ma",
    },
    "MT": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/mt",
    },
    "MS": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/ms",
    },
    "MG": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/mg",
    },
    "PA": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/pa",
    },
    "PB": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/pb",
    },
    "PR": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/pr",
    },
    "PE": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/pe",
    },
    "PI": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/pi",
    },
    "RJ": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/rj",
    },
    "RN": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/rn",
    },
    "RS": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/rs",
    },
    "RO": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/ro",
    },
    "RR": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/rr",
    },
    "SC": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/sc",
    },
    "SP": {
        stepName: "SP",
        decisionKey: "http://localhost/edital/sp",
    },
    "SE": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/se",
    },
    "TO": {
        stepName: "titulo",
        decisionKey: "http://localhost/edital/to",
    }
};
