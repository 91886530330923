import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
  Paper,
  Grid,
  Checkbox as MUICheckbox,
  Typography,
  Divider,
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField,
  Select,
  Checkbox,
  CNPJField,
  Autocomplete,
  TelField,
  CEPField,
  SelectUF,
  SelectCidade,
  DialogoVisualizador,
  DialogoConfirmacao,
  Radio,
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { constituicaoEmpresaFornecedor } from '../../static';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogoFornecedor = ({
  open, onClose, fornecedor
}) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [tecnologias, setTecnologias] = React.useState([]);
  const [regioes, setRegioes] = React.useState([]);
  const [categorias, setCategorias] = React.useState([]);
  const [fileVisualizador, setFileVisualizador] = React.useState();
  const [openDlgVis, setOpenDlgVis] = React.useState(false);
  const [openDlgJustificativa, setOpenDlgJustificativa] = React.useState(false);

  const [dlgJustTitulo, setDlgJustTitulo] = React.useState("");
  const [dlgJustNomeCampoAprovado, setDlgJustNomeCampoAprovado] = React.useState("");  //(nomeCampoAprovado);
  const [dlgJustNomeCampoJustificativa, setDlgJustNomeCampoJustificativa] = React.useState("");  //(nomeCampoJustificativa);
  const [dlgJustFornecedorId, setDlgJustFornecedorId] = React.useState("");  //(fornecedorId);
  const [statusBotao, setStatusBotao] = React.useState('');

  const defaultValues = {
    nome: '',
    responsavel: '',
    email: '',
    cnpj: '',
    habilitado: true,
    regioes: [],
    tecnologias: []
  };
  const schema = yup.object({
    nome: yup.string().label('Nome da empresa').required(),
    responsavel: yup.string().label('Nome do responsável').required(),
    email: yup.string().email().label('Email do responsável').required(),
    cnpj: yup.string().cnpj().label('CNPJ').required(),
    tecnologias: yup.array(yup.number().positive().integer())
      .min(1).nullable().label('Tecnologia').required(),
    regioes: yup.array(yup.number().positive().integer())
      .min(1).nullable().label('Região').required(),
    atendeEstados: yup.string().optional().label('Em quais estados a empresa deseja atuar?'),
    emailAux: yup.string().email().label("E-mail secundário").required(),
    telefone: yup.string().label("Telefone").required(),
    cep: yup.string().optional().label("CEP"),
    rua: yup.string().optional().label("Rua"),
    numero: yup.string().optional().label("Número"),
    bairro: yup.string().optional().label("Bairro"),
    cidade: yup.string().optional().label("Cidade"),
    uf: yup.string().optional().label("Estado"),

    atuaEsco: yup.boolean().nullable().label('A empresa atua como uma ESCO?'),
    atuaBot: yup.boolean().nullable().label('A empresa atua na modalidade de BOT?'),
    constituicaoempresa: yup.number().nullable().label('Qual o porte da empresa?'),
    idDocumento: yup.number().positive().nullable().label('Selecionar').optional(),

    observacoes: yup.string().optional().label("Observações"),

    actAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    actJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    catAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    catJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    declaracaoFabricanteAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    declaracaoFabricanteJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    certidaoCreaAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    certidaoCreaJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    pedidoPublicoAnexo1Aprovado: yup.boolean().nullable().label("Documento Reprovado"),
    pedidoPublicoAnexo1Justificativa: yup.string().nullable().label('Justificativa Reprovação'),

    outrasInformacoesAnexo9Aprovado: yup.boolean().nullable().label("Documento Reprovado"),
    outrasInformacoesAnexo9Justificativa: yup.string().nullable().label('Justificativa Reprovação'),

    documentosMeiAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    documentosMeiJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    atoSociedadeSimplesAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    atoSociedadeSimplesJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    atoSociedadeAcoesAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    atoSociedadeAcoesJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    negativaFalenciaAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    negativaFalenciaJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    certidaoVirgenteAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    certidaoVirgenteJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    balancoPatrimonialAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    balancoPatrimonialJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    provaInscCnpjAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    provaInscCnpjJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    cadastroContribuinteMunicipalAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    cadastroContribuinteMunicipalJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    cadastroContribuinteEstadualAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    cadastroContribuinteEstadualJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFazendaNacionalAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    regularidadeFazendaNacionalJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFazendaEstadualAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    regularidadeFazendaEstadualJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFazendaMunicipalAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    regularidadeFazendaMunicipalJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeFgtsAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    regularidadeFgtsJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    regularidadeJusticaTrabalhoAprovado: yup.boolean().nullable().label("Documento Reprovado"),
    regularidadeJusticaTrabalhoJustificativa: yup.string().nullable().label('Justificativa Reprovação'),

    habilitado: yup.boolean().label('Habilitado').required()
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  // load categorias, tecnologia e regiões
  React.useEffect(async () => {
    let mount = {
      isMounted: true
    };
    loadCategorias(mount);
    loadTecnologias(mount);
    loadRegioes(mount);
    //loadEstados(mount);
    setStatusBotao(fornecedor?.estagioFornecedor)
    return () => { mount.isMounted = false; };
  }, []);

  const loadCategorias = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/categorias')).data;
      if (mount.isMounted) {
        setCategorias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // load tecnologias
  const loadTecnologias = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/tecnologias')).data;
      if (mount.isMounted) {
        setTecnologias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // load regiões
  const loadRegioes = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/regioes')).data;
      if (mount.isMounted) {
        setRegioes(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // const loadEstados = async (mount) => {
  //   startLoading();
  //   try {
  //     let data = (await api.http.get('/estados')).data;
  //     console.log("data: ", data);
  //     if (mount.isMounted) {
  //       const sortedStates = data.sort((a, b) => {
  //         return a.nome.localeCompare(b.nome); // Comparando os nomes dos estados
  //       });

  //       setStates(sortedStates);
  //     }
  //     stopLoading();
  //   } catch (error) {
  //     stopLoading({ message: error.toString(), severity: 'error' });
  //   }
  // };

  const { handleSubmit, reset, formState: { isDirty } } = useFormRef;

  const onSave = async (data) => {
    startLoading();
    try {
      if (data.id) {
        await api.http.put('/fornecedores/documentos/' + data.id, data);
      } else {
        //await api.http.post('/fornecedores', data);

      }
      stopLoading({ severity: 'success' });
      // const resultado = exibeBotao(data);
      // setStatusBotao(resultado);
      // if (resultado === 2) {
      close(true);
      // }
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  // const onAprova = async () => {
  //   console.log("analise aprovado");
  // }

  // const onPendencia = async () => {
  //   console.log("existe pendencia");
  // }

  // const exibeBotao = (data) => {
  //   let statusBotao = 2;
  //   // analise

  //   const isAnyFieldNullOrUndefined = [
  //     data.actAprovado,
  //     data.catAprovado,
  //     data.declaracaoFabricanteAprovado,
  //     data.certidaoCreaAprovado,
  //     data.pedidoPublicoAnexo1Aprovado,
  //     data.outrasInformacoesAnexo9Aprovado,
  //     data.negativaFalenciaAprovado,
  //     data.certidaoVirgenteAprovado,
  //     data.balancoPatrimonialAprovado,
  //     data.provaInscCnpjAprovado,
  //     data.cadastroContribuinteMunicipalAprovado,
  //     data.cadastroContribuinteEstadualAprovado,
  //     data.regularidadeFazendaNacionalAprovado,
  //     data.regularidadeFazendaEstadualAprovado,
  //     data.regularidadeFazendaMunicipalAprovado,
  //     data.regularidadeFgtsAprovado,
  //     data.regularidadeJusticaTrabalhoAprovado,
  //   ].some((field) => field === null || field === undefined);

  //   const isAnyFieldZeroOrFalse = [
  //     data.actAprovado,
  //     data.catAprovado,
  //     data.declaracaoFabricanteAprovado,
  //     data.certidaoCreaAprovado,
  //     data.pedidoPublicoAnexo1Aprovado,
  //     data.outrasInformacoesAnexo9Aprovado,
  //     data.negativaFalenciaAprovado,
  //     data.certidaoVirgenteAprovado,
  //     data.balancoPatrimonialAprovado,
  //     data.provaInscCnpjAprovado,
  //     data.cadastroContribuinteMunicipalAprovado,
  //     data.cadastroContribuinteEstadualAprovado,
  //     data.regularidadeFazendaNacionalAprovado,
  //     data.regularidadeFazendaEstadualAprovado,
  //     data.regularidadeFazendaMunicipalAprovado,
  //     data.regularidadeFgtsAprovado,
  //     data.regularidadeJusticaTrabalhoAprovado,
  //   ].some((field) => field === 0 || field === false);

  //   if (isAnyFieldNullOrUndefined) {
  //     if ((data.constituicaoempresa === 1 && (data.documentosMeiAprovado === null || data.documentosMeiAprovado === undefined))
  //       || (data.constituicaoempresa === 2 && (data.atoSociedadeSimplesAprovado === null || data.atoSociedadeSimplesAprovado === undefined))
  //       || (data.constituicaoempresa === 3 && (data.atoSociedadeAcoesAprovado === null || data.atoSociedadeAcoesAprovado === undefined))
  //     ) {
  //       console.log("Algum campo está null ou undefined.");
  //       statusBotao = 2;
  //     }
  //   } else {
  //     if ((data.constituicaoempresa === 1 && (data.documentosMeiAprovado !== null && data.documentosMeiAprovado !== undefined))
  //       || (data.constituicaoempresa === 2 && (data.atoSociedadeSimplesAprovado !== null && data.atoSociedadeSimplesAprovado !== undefined))
  //       || (data.constituicaoempresa === 3 && (data.atoSociedadeAcoesAprovado !== null && data.atoSociedadeAcoesAprovado !== undefined))
  //     ) {

  //       let isConstituicaoEmpresa = false;
  //       if ((data.constituicaoempresa === 1 && (data.documentosMeiAprovado === 0 && data.documentosMeiAprovado === false))
  //         || (data.constituicaoempresa === 2 && (data.atoSociedadeSimplesAprovado === 0 && data.atoSociedadeSimplesAprovado === false))
  //         || (data.constituicaoempresa === 3 && (data.atoSociedadeAcoesAprovado === 0 && data.atoSociedadeAcoesAprovado === false))
  //       ) {
  //         isConstituicaoEmpresa = false;
  //       }
  //       else {
  //         isConstituicaoEmpresa = true;
  //       }

  //       if (isAnyFieldZeroOrFalse && !isConstituicaoEmpresa) {
  //         // pendencia
  //         console.log("Algum campo está preenchido com 0 ou false.");
  //         statusBotao = 3;
  //       } else {
  //         // aprovado
  //         console.log("Nenhum campo está preenchido com 0 ou false.");
  //         statusBotao = 4
  //       }
  //     }
  //   }

  //   console.log("exibebotao: ", statusBotao);

  //   return statusBotao;
  // }

  const close = (reload = false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(() => {
    if (fornecedor) {
      reset({
        ...fornecedor,
        regioes: fornecedor.regiao.map(regiao => regiao.id),
        tecnologias: fornecedor.tecnologia.map(tecnologia => tecnologia.id)
      });
    }
  }, [fornecedor]);



  const onConfirmaDlgJustificativa = async (data) => {
    //confirmar a justificativa
    console.log("confirmar justificativa")

    startLoading();
    try {

      console.log("data: ", data);

      let datar = (await api.http.put('/fornecedores/document/reprove/' + dlgJustFornecedorId, {

        id: dlgJustFornecedorId,
        nomeCampo: dlgJustNomeCampoAprovado,
        nomeCampoJustificativa: dlgJustNomeCampoJustificativa,
        justificativa: data.observacoes,
      })).data;

      // setFornecedor(data[0]);

      stopLoading({ severity: 'success' });
      // setOpenConfirmaExcluir(false);

    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
      // setOpenConfirmaExcluir(false);
      throw error;
    }

    setOpenDlgJustificativa(false);
  };

  const onNaoConfirmaDlgJustificativa = () => {
    setOpenDlgJustificativa(false);
  };

  const onOpenDlgVis = (param1, param2, param3) => {
    setFileVisualizador(param1);
    setOpenDlgVis(true);
  };

  const onCloseDlgVis = () => {
    setOpenDlgVis(false);
  };

  const onAproveDocumento = async (nomeCampo, idFornecedor) => {
    //("actProvado", fornecedor.id)
    //chamar a api para registar o ok

    console.log("botão aprovado");
    console.log(nomeCampo);
    console.log(idFornecedor);

    startLoading();
    try {

      let data = (await api.http.put('/fornecedores/document/approve/' + idFornecedor, {
        id: idFornecedor,
        nomeCampo: nomeCampo,
      })).data;

      // setFornecedor(data[0]);

      stopLoading({ severity: 'success' });
      // setOpenConfirmaExcluir(false);

    } catch (error) {
      stopLoading({ message: error.toString(), severity: 'error' });
      // setOpenConfirmaExcluir(false);
      throw error;
    }

  }

  const onReproveDocumento = (nomeCampo, nomeCampoJustificativa, idFornecedor, titulo) => {
    //("actAprovado", "actJustificativa", fornecedor.id)
    //chamar a api para registar o ok

    console.log("onOpenConfirmaDlgJustificativa")
    console.log(nomeCampo)
    console.log(nomeCampoJustificativa)
    console.log(idFornecedor)
    console.log(titulo)

    setDlgJustNomeCampoAprovado(nomeCampo);
    setDlgJustNomeCampoJustificativa(nomeCampoJustificativa);
    setDlgJustFornecedorId(idFornecedor);
    setDlgJustTitulo(titulo);
    setOpenDlgJustificativa(true);

  }

  console.log("fornecedor: ", fornecedor);

  return (
    <>
      <DialogoVisualizador file={fileVisualizador}
        open={openDlgVis}
        onClose={() => onCloseDlgVis} />

      <DialogoConfirmacao
        title="Atenção"
        open={openDlgJustificativa}
        onSim={onConfirmaDlgJustificativa}
        onNao={onNaoConfirmaDlgJustificativa}
      >
        <h5>{dlgJustTitulo}</h5>
        Descreva aqui a justificativa para a não aprovar o documento.
      </DialogoConfirmacao>

      <Dialog open={open} onClose={() => close()}>
        <DialogTitle>Dados do fornecedor</DialogTitle>
        <DialogContent dividers>
          <FormControl sx={{ my: 2 }} fullWidth>
            <CNPJField
              margin="dense"
              fullWidth
              field="cnpj"
              useFormRef={useFormRef}
              schema={schema}
              disabled={true}
              InputLabelProps={{ shrink: true }} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="nome"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="responsavel"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="email"
              type="email"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>
          {/* <FormControl sx={{ my: 2 }} fullWidth>
      <CNPJField
        margin="dense"
        fullWidth
        field="cnpj"
        useFormRef={useFormRef} schema={schema} />
    </FormControl> */}

          <FormControl sx={{ my: 1 }} fullWidth>
            <TextField
              margin="dense"
              fullWidth
              field="emailAux"
              type="email"
              useFormRef={useFormRef}
              // disabled={disabled}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 1 }} fullWidth>
            <TelField
              field="telefone"
              // disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
              InputLabelProps={{ shrink: true }} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <CEPField
              field="cep"
              // disabled={disabled}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>


          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="rua"
              // disabled={disabled}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="numero"
              // disabled={disabled}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="bairro"
              // disabled={disabled}
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="uf"
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>


          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="cidade"
              useFormRef={useFormRef}
              schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <Autocomplete
              multiple
              disableCloseOnSelect
              field="tecnologias"
              useFormRef={useFormRef}
              schema={schema}
              options={tecnologias}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <MUICheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected} />
                  {props.key}
                </li>
              )}
              groupBy={(option) => 'Categoria ' +
                option.categoria
                + ': ' +
                categorias.filter((categoria) => categoria.id == option.categoria)
                  .map((label) => label.nome)} />
          </FormControl>
          <FormControl sx={{ my: 2 }} fullWidth>
            <Autocomplete
              multiple
              disableCloseOnSelect
              field="regioes"
              useFormRef={useFormRef}
              schema={schema}
              options={regioes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <MUICheckbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected} />
                  {props.key}
                </li>
              )} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="atendeEstados"
              useFormRef={useFormRef}
              schema={schema} />
            {/* <Autocomplete
      multiple
      disableCloseOnSelect
      field="estados"
      useFormRef={useFormRef}
      schema={schema}
      // disabled={disabled}
      options={states}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <MUICheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {props.key}
        </li>
      )}
    /> */}
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <Checkbox field="atuaEsco"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>

          <FormControl sx={{ my: 2 }} fullWidth>
            <Checkbox field="atuaBot"
              useFormRef={useFormRef} schema={schema} />
          </FormControl>

          <FormControl x={{ mb: 2 }} fullWidth>
            <Select
              field="constituicaoempresa"
              useFormRef={useFormRef} schema={schema}
              options={constituicaoEmpresaFornecedor} />
          </FormControl>

          <Divider>
            <h3>Documentação Técnica</h3>
            <h3>Qualificação Técnica</h3>
          </Divider>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">1. ACT</Typography>
            {/* <Button
              color="secondary"
              onClick={() => onOpenDlgVis(fornecedor.arquivoAct)}>Visualizar</Button> */}
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                startIcon={<ArticleOutlinedIcon />}
                onClick={() => onOpenDlgVis(fornecedor?.arquivoAct)}
              >
                Visualizar
              </Button>
            )}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="actAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="actAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="actJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box  
          }}>
            <Typography variant="body1">2. CAT</Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoCat)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="catAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="catAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="catJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>

              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              3. Declaração do fabricante
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivodeclaracaoFabricanteId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="declaracaoFabricanteAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="declaracaoFabricanteAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="declaracaoFabricanteJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>

              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              4. Certidão de Registro - CREA Pessoa Juridica
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivocertidaoCreaId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="certidaoCreaAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="certidaoCreaAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="certidaoCreaJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Divider>
            <h3>Documentação habilitação</h3>
            <h3>Qualificação Técnica</h3>
          </Divider>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box  
          }}>
            <Typography variant="body1">
              5. Pedido Público (Anexo I)
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivopedidoPublicoAnexo1Id)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="pedidoPublicoAnexo1Aprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="pedidoPublicoAnexo1Aprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="pedidoPublicoAnexo1Justificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              6. Declaração de outras informações (Anexo IX)
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivooutrasInformacoesAnexo9)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="outrasInformacoesAnexo9Aprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="outrasInformacoesAnexo9Aprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="outrasInformacoesAnexo9Justificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box  
          }}>
            <Typography variant="body1">
              7. Documentos - MEI
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivodocumentosMeiId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="documentosMeiAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="documentosMeiAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="documentosMeiJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              7. Ato Constitutivo - Sociedades Simples
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoatoSociedadeSimplesId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="atoSociedadeSimplesAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="atoSociedadeSimplesAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="atoSociedadeSimplesJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              7. Ato Constitutivo - Sociedades por Ações
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoatoSociedadeAcoesId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="atoSociedadeAcoesAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="atoSociedadeAcoesAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="atoSociedadeAcoesJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Divider>
            <h3>Qualificação Econômico-Financeira</h3>
          </Divider>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              8. Certidão Negativa Falência ou Certidão Judicial - não superior a 90 dias
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivonegativaFalenciaId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="negativaFalenciaAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="negativaFalenciaAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="negativaFalenciaJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              9. Certidão vigente emitida pela instância Judicial
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivocertidaoVirgenteId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="certidaoVirgenteAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="certidaoVirgenteAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="certidaoVirgenteJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              10. Balanço Patrimonial e Demonst. contábeis
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivobalancoPatrimonialId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="balancoPatrimonialAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="balancoPatrimonialAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="balancoPatrimonialJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Divider>
            <h3>Regularidade Fiscal</h3>
          </Divider>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              11. Prova de Inscrição - CNPJ
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoprovaInscCnpjId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="provaInscCnpjAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="provaInscCnpjAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="provaInscCnpjJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box  
          }}>
            <Typography variant="body1">
              12. Cadastro de Contribuinte Municipal
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivocadastroContribuinteMunicipalId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="cadastroContribuinteMunicipalAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="cadastroContribuinteMunicipalAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="cadastroContribuinteMunicipalJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box  
          }}>
            <Typography variant="body1">
              13. Cadastro de Contribuinte Estadual
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivocadastroContribuinteEstadualId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="cadastroContribuinteEstadualAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="cadastroContribuinteEstadualAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="cadastroContribuinteEstadualJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box  
          }}>
            <Typography variant="body1">
              14. Prova de Regularidade - Fazenda Nacional
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined"
                color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaNacionalId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="regularidadeFazendaNacionalAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="regularidadeFazendaNacionalAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="regularidadeFazendaNacionalJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box  
          }}>
            <Typography variant="body1">
              15. Prova de Regularidade - Fazenda Estadual
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined" color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoregulariadeFazendaEstadualId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="regularidadeFazendaEstadualAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="regularidadeFazendaEstadualAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="regularidadeFazendaEstadualJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              16. Prova de Regularidade - Fazenda Municipal
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined" color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFazendaMunicipalId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="regularidadeFazendaMunicipalAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="regularidadeFazendaMunicipalAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="regularidadeFazendaMunicipalJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              17. Prova de Regularidade - FGTS
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined" color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeFgtsId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="regularidadeFgtsAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="regularidadeFgtsAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="regularidadeFgtsJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          <Box sx={{
            backgroundColor: 'white',
            borderBottom: '1px solid #FAFBFB', // Borda preta de 1px
            marginTop: 2, // Espaçamento antes do Box (equivalente a 16px)
            marginBottom: 2, // Espaçamento depois do Box (equivalente a 16px)
            padding: 2 // Opcional: adiciona padding dentro do Box 
          }}>
            <Typography variant="body1">
              18. Prova de Regularidade – Justiça do Trabalho
            </Typography>
            {fornecedor?.estagioFornecedor !== "1" && (
              <Button variant="outlined" color="secondary"
                onClick={() => onOpenDlgVis(fornecedor.arquivoregularidadeJusticaTrabalhoId)}
                startIcon={<ArticleOutlinedIcon />}>
                Visualizar
              </Button>)}
            {fornecedor?.estagioFornecedor === "2" && (
              <>
                <Radio
                  field="regularidadeJusticaTrabalhoAprovado"
                  schema={schema}
                  useFormRef={useFormRef}
                  options={[
                    { label: 'OK', value: 1 },
                    { label: 'Não OK', value: 0 }
                  ]}
                />
                {/* <FormControl sx={{ my: 2 }} fullWidth>
                  <Checkbox field="regularidadeJusticaTrabalhoAprovado"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl> */}
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    margin="dense"
                    fullWidth
                    field="regularidadeJusticaTrabalhoJustificativa"
                    useFormRef={useFormRef} schema={schema} />
                </FormControl>
              </>
            )}
          </Box>

          {/* <FormControl sx={{ my: 2 }} fullWidth>
            <Checkbox field="habilitado"
              useFormRef={useFormRef} schema={schema} />
          </FormControl> */}
        </DialogContent >
        <DialogActions>
          {loading > 0 && <CircularProgress
            size={30}
            sx={{
              'position': 'absolute',
              'left': 25,
              'bottom': 12
            }} />}
          <Button color="inherit" onClick={() => close()}>
            CANCELAR
          </Button>

          {fornecedor?.estagioFornecedor === "2" && (
            <Button color="primary"
              onClick={handleSubmit(onSave)} disabled={!isDirty}>
              SALVAR
            </Button>
          )}


          {/* {statusBotao === 4 && (
            <Button color="primary"
              onClick={handleSubmit(onAprova)} disabled={!isDirty}>
              APROVAR
            </Button>
          )}
          {statusBotao === 3 && (
            <Button color="primary"
              onClick={handleSubmit(onPendencia)} disabled={!isDirty}>
              PENDÊNCIA
            </Button>
          )} */}
        </DialogActions>
      </Dialog ></>

  );
};


export default DialogoFornecedor;
