import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Checkbox as MUICheckbox,
} from "@mui/material";

import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Autocomplete, FileListField, Select, DateField }
  from "../../components";
import api from "../../services/api";
import { tiposCargas } from "../../static";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



const DialogoSolicitacaoOrcamentosNovos = ({ open, onClose, disabled }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);
  // const [tecnologias, setTecnologias] = React.useState([]);
  // const [regioes, setRegioes] = React.useState([]);
  // const [categorias, setCategorias] = React.useState([]);
  // const [solicitacaoOrcamentos, setSolicitacaoOrcamentos] = React.useState(null);

  const defaultValues = {
    tipoCarga: "",
    finishedAt: "",
  };
  const schema = yup
    .object({
      tipoCarga: yup
        .string()
        .label("Tecnologia")
        .required("Selecione a tecnologia que deseja tomar os preços"),
      finishedAt: yup.string().label("Data máxima para resposta"),
    })
    .required();


  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormRef;



  const onSave = async (data) => {
    startLoading();
    try {
      await api.http.post("/solicitacoes-orcamentos", {
        ...data,
        processoId: currProcesso.id,
      });
      stopLoading({ severity: "success" });
      // close();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const close = () => {
    reset(defaultValues);
    onClose();
  };

  // React.useEffect(async () => {
  //   if (open) {
  //     startLoading();
  //     try {
  //       let data = (
  //         await api.http.get("/solicitacoes-orcamentos/" + currProcesso.id)
  //       ).data;
  //       reset(data);
  //       setSolicitacaoOrcamentos(data);
  //       stopLoading();
  //     } catch (error) {
  //       if (!error.response || error.response.status != 404) {
  //         stopLoading({
  //           message: error.toString(),
  //           severity: "error",
  //         });
  //       } else stopLoading();
  //     }
  //   }
  // }, [open]);

  // const isDisabled = () => {
  //   return disabled || solicitacaoOrcamentos != null;
  // };

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Dados para tomada de preços</DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="tipoCarga"
            useFormRef={useFormRef}
            schema={schema}
            options={tiposCargas}
            getOptionLabel={(option) => option.label}
          //disabled={true}
          />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <DateField
            field="finishedAt"
            inputFormat={"dd/MM/yyyy"}
            //maxDate={new Date()}
            useFormRef={useFormRef}
            schema={schema}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button color="inherit" onClick={() => close()}>
          CANCELAR
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(onSave)}
          //disabled={isDisabled() || !isDirty}
          disabled={!isDirty}
        >
          SOLICITAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoSolicitacaoOrcamentosNovos;
