import React from 'react';
import { Modal, Box, Typography, Button, Grid, Paper, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import './style.css';

const FornecedorDialogoPropostaOrcamentoIlum = ({ open, handleClose, title, children }) => {
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        height: '90vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#62bb46',
            color: '#FFFFFF',
            fontWeight: 'bold',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const initialRows = [
        createData(1, 'Led', 'Tubular T8', 20, 1800, 20, 0),
        createData(2, 'Led', 'Tubular T5', 10, 900, 15, 0),
        // Adicione mais linhas se necessário
    ];

    const [rows, setRows] = React.useState(initialRows);

    const handleValorUnitarioChange = (index, value) => {
        const newRows = [...rows];
        newRows[index].valorUnitario = parseFloat(value) || 0;
        newRows[index].valorTotal = newRows[index].quantidade * newRows[index].valorUnitario;
        setRows(newRows);
    };

    const valorGeral = rows.reduce((acc, row) => acc + row.valorTotal, 0);

    function createData(id, discriminacao, possuifiname, finame, possuicodpotencialize,
        codpotencialize, tipo, formato, potencia, luminosidade,
        fatorpotencia, eficiencialuminosa, temperaturacor, temogarantia,
        possuiprocel, classificacaoImetro, vidautil,
        quantidade, valorUnitario,
        totalequipamento, valorinstalacao, valordescarte) {
        return {
            id, discriminacao, possuifiname, finame, possuicodpotencialize,
            codpotencialize, tipo, formato, potencia, luminosidade,
            fatorpotencia, eficiencialuminosa, temperaturacor, temogarantia,
            possuiprocel, classificacaoImetro, vidautil,
            quantidade, valorUnitario,
            totalequipamento, valorinstalacao, valordescarte, valorTotal: 0
        };
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">
                    {title}
                </Typography>
                <Box className='title'>
                    <Typography variant="h6" gutterBottom className='margemtexto'>
                        PEDIDO DE ORÇAMENTO:
                    </Typography>
                </Box>
                <Box className='title'>
                    <Typography variant="h6" gutterBottom className='margemtexto'>
                        Dados de identificação do solicitante:
                    </Typography>
                </Box>
                <Grid container spacing={0}>
                    <Grid item xs={4} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>Cidade: </Typography>
                    </Grid>
                    <Grid item xs={8} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>São Paulo</Typography>
                    </Grid>
                    <Grid item xs={4} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>UF: </Typography>
                    </Grid>
                    <Grid item xs={8} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>SP</Typography>
                    </Grid>
                    <Grid item xs={4} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>Validade da proposta </Typography>
                    </Grid>
                    <Grid item xs={8} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> 21 </Typography>
                    </Grid>
                </Grid>
                <Box className='title'>
                    <Typography variant="h6" gutterBottom className='margemtexto'>
                        INFORMAÇÕES ADICIONAIS - LÂMPADAS QUE PREFERENCIALMENTE APRESENTEM:
                    </Typography>
                </Box>
                <Grid container spacing={0}>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>1</Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> Fator de potência igual ou superior a 0,92 </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>2</Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> Alta eficiência luminosa, com eficiência mínima de 80 lm/W </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>3</Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> Temperatura de cor, preferencialmente, de 4000K </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>4</Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> Garantia de, no mínimo, 3 anos </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>5</Typography>
                    </Grid>
                    <Grid item xs={8} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> De preferência, equipamentos que apresentem selo Procel ou certificado do INMETRO nível A </Typography>
                    </Grid>
                </Grid>

                <Box className='title'>
                    <Typography variant="h6" gutterBottom className='margemtexto'>
                        INFORMAÇÕES ADICIONAIS QUE DEVEM ESTAR CONTIDAS NA PROPOSTA:
                    </Typography>
                </Box>
                <Grid container spacing={0}>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> 1</Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> Indicar o prazo para envio dos equipamentos (por exemplo, 30 dias, 60 dias, etc). Lembrando que esse prazo será o mesmo da proposta de orçamento da 2ª rodada </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>2</Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> Indicar o valor unitário de cada equipamento. O valor total de cada equipamento será calculado automaticamente </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>3</Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> Informar o prazo para envio dos equipamentos  </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            4
                        </Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            Indicar o valor unitário de cada equipamento e o valor total de cada equipamento
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            5
                        </Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            A Discriminação dos itens ofertadas nas tabelas de fornecimento deverá ser sucinta, porém todos itens referentes a Materiais e Equipamentos deverão acompanhar a ficha técnica do produto
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            6
                        </Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            Produtos ofertados que atendam requisitos mínimos de Desempenho Energético (se pertinente) serão utilizados como critério de seleção do fornecedor.
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            7
                        </Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            A proposta deverá OBRIGATORIAMENTE considerar o custo de descarte assim como informar a título de anexo o procedimento de logística reversa que será adotado pela empresa (sujeito a diligência por parte do programa)
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            8
                        </Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            Toda a documentação referente a proposta, deverá ser encaminhada direta e exclusivamente via sistema de M&A (login e senha)
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            9
                        </Typography>
                    </Grid>
                    <Grid item xs={11} className="item-response">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                            No item Cronograma físico deverão ser elencadas todas as atividades vinculadas a implementação da Medida de Eficiência Energética ( por exemplo: MEE: Troca de caldeira, Atividade 1: Retirar a caldeira antiga, Indicador: Caldeira retirada)
                        </Typography>
                    </Grid>
                </Grid>

                <Box className='title titleBottom'>
                    <Typography variant="h6" gutterBottom className='margemtexto'>
                        INFORMAÇÕES SOBRE AS LÂMPADAS A SEREM ORÇADAS:
                    </Typography>
                </Box>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>ID</StyledTableCell>
                                <StyledTableCell>Discriminação/Fabricante/Modelo</StyledTableCell>
                                <StyledTableCell>Tipo - Se houver código FINAME indique na coluna ao lado</StyledTableCell>
                                <StyledTableCell>Código FINAME</StyledTableCell>
                                <StyledTableCell>CÓDIGO POTENCIALIZEE - Se houver código PotencializEE, indique na coluna ao lado</StyledTableCell>
                                <StyledTableCell>Código POTENCIALIZEE</StyledTableCell>
                                <StyledTableCell>Tipo da Lâmpada</StyledTableCell>
                                <StyledTableCell>Formato da Lâmpada</StyledTableCell>
                                <StyledTableCell>Potência (w)</StyledTableCell>
                                <StyledTableCell>Luminosidade (lumens)</StyledTableCell>
                                <StyledTableCell>Fator de Potência</StyledTableCell>
                                <StyledTableCell>Eficiência luminosa (lm/w)</StyledTableCell>
                                <StyledTableCell>Temperatura de cor (k)</StyledTableCell>
                                <StyledTableCell>Tempo de garantia (anos)</StyledTableCell>
                                <StyledTableCell>Possui selo Procel</StyledTableCell>
                                <StyledTableCell>Classificação INMETRO</StyledTableCell>
                                <StyledTableCell>Vida útil (horas)</StyledTableCell>
                                <StyledTableCell>Quantidade</StyledTableCell>
                                <StyledTableCell>Valor unitário por equipamento (R$)</StyledTableCell>
                                <StyledTableCell>Valor total por equipamento (R$)</StyledTableCell>
                                <StyledTableCell>Valor previsto para Instalação (R$)</StyledTableCell>
                                <StyledTableCell>Valor previsto para descate (R$) - se aplicável</StyledTableCell>
                                <StyledTableCell>Valor Total (R$)</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>{row.id}</StyledTableCell>
                                    <StyledTableCell>{row.tipo}</StyledTableCell>
                                    <StyledTableCell>{row.formato}</StyledTableCell>
                                    <StyledTableCell>{row.potencia}</StyledTableCell>
                                    <StyledTableCell>{row.luminosidade}</StyledTableCell>
                                    <StyledTableCell>{row.quantidade}</StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            value={row.valorUnitario}
                                            onChange={(e) => handleValorUnitarioChange(index, e.target.value)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>{row.valorTotal.toFixed(2)}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow>
                                <StyledTableCell colSpan={7} align="right">Total (R$)</StyledTableCell>
                                <StyledTableCell>{valorGeral.toFixed(2)}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid container spacing={0}>
                    <Grid item xs={4} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'> PRAZO PARA ENVIO DO EQUIPAMENTO </Typography>
                    </Grid>
                    <Grid item xs={8} className="item-response">
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                        //value={row.valorUnitario}
                        //onChange={(e) => handleValorUnitarioChange(index, e.target.value)}
                        /> DIAS
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className="item-response-color">
                        <Typography variant="subtitle2" gutterBottom className='margemtexto'>CONDIÇÕES DE PAGAMENTO</Typography>
                    </Grid>
                    <Grid item xs={8} className="item-response">

                        <TextField
                            type="text"
                            variant="outlined"
                            size="small"
                            sx={{ width: '90%' }}
                        //value={row.valorUnitario}
                        //onChange={(e) => handleValorUnitarioChange(index, e.target.value)}
                        />

                    </Grid>
                </Grid>

                {/* <Box sx={{ mt: 2 }}>
                    {children}
                </Box> */}
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Button variant="contained" color="success" onClick={handleClose} sx={{ mt: 2 }}>
                            Confirmar valores
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" color="secondary" onClick={handleClose} sx={{ mt: 2, ml: 'auto', display: 'block' }}>
                            Fechar Modal
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default FornecedorDialogoPropostaOrcamentoIlum;
